import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../button/Button";
import { usePrevious } from "@pepdirect/helpers/usePrevious";
import { colors } from "../../styles/variables";

interface StyledBannerDetails {
  isError: boolean;
}

const StyledBanner = styled.div<StyledBannerDetails>`
  display: flex;
  justify-content: center;

  ${({ isError, theme }) =>
    isError
      ? `
        background-color: ${colors.orange};
      `
      : `
      background-color: ${theme.banner?.backgroundColor || colors.green};
      `}
`;

const InnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${colors.white};
  text-align: center;
  padding: 0 20px;

  Button {
    padding-left: 10px;
    color: ${colors.white};
    text-decoration: underline;
    font-family: ${"font-primary" || "inherit"};
    font-weight: 400;
  }
`;

const StyledButton = styled(Button)`
  padding-left: 3px;
  color: ${colors.white};
  text-decoration: underline;
`;

const Content = styled.p`
  margin: 10px 0;
`;

export interface BannerContextInterface {
  setBannerDetails: (details: BannerDetails | null) => void;
  bannerDetails: BannerDetails | null;
  setBannerAutoDismiss: (dismissBanner: boolean) => void;
  bannerAutoDismiss: boolean;
}

export interface BannerDetails {
  displayText: string;
  type: "success" | "error";
  autoDismiss?: boolean;
}

export interface BannerProps extends BannerDetails {
  setBannerDetails: (props: BannerDetails | null) => void;
  bannerAutoDismiss: boolean;
  setBannerAutoDismiss: (dismissBanner: boolean) => void;
}

/**
 * To use the banner:
 * 1. use the BannerContext
 *    `const { setBannerDetails } = useContext(BannerContext)
 *    setBannerDetails({ displayText: "Text to display", type: "success"});`
 * 2. Style note: KEEP YOUR DISPLAY TEXT SHORT
 *    Always end your display text with a period. Please.
 *    ~30 - 33 characters is ideal, otherwise it looks not great
 */
export const Banner: FC<BannerProps> = ({
  displayText,
  type,
  autoDismiss,
  setBannerDetails,
  bannerAutoDismiss,
  setBannerAutoDismiss,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const prevAutoDismiss = usePrevious(autoDismiss);

  useEffect(() => {
    // show banner only once, then auto-dismiss on next mounting of banner
    if (autoDismiss && !bannerAutoDismiss) {
      setBannerAutoDismiss(true);
    }
  }, [autoDismiss, bannerAutoDismiss, setBannerAutoDismiss]);

  // This useEffect will re-render when the above useEffect is run because of
  // a change in bannerAutoDismiss in the dep array. In order to prevent this
  // from getting called on re-render and ensure it's called on next mount,
  // we watch the previously rendered version of autoDismiss via
  // prevAutoDismiss. If it is a re-render, bannerAutoDismiss and
  // prevAutoDismiss will be true, which means we just started showing it
  // and we don't want to dismiss it yet! If it is the next mount,
  // bannerAutoDismiss will still be true from the context but prevAutoDismiss
  // will now be undefined, so now we can stop showing it.
  useEffect(() => {
    if (bannerAutoDismiss && !prevAutoDismiss) {
      setBannerDetails(null);
      setBannerAutoDismiss(false);
    }
  }, [
    bannerAutoDismiss,
    prevAutoDismiss,
    setBannerAutoDismiss,
    setBannerDetails,
  ]);

  useEffect(() => {
    if (isDismissed) {
      setBannerDetails(null);
      setBannerAutoDismiss(false);
    }
  }, [isDismissed, setBannerDetails, setBannerAutoDismiss]);

  if (isDismissed) return null;

  return (
    <StyledBanner isError={type === "error"} data-testid="banner-component">
      <InnerWrapper>
        <Content>
          {displayText}
          <StyledButton type="text" onClick={() => setIsDismissed(true)}>
            Dismiss
          </StyledButton>
        </Content>
      </InnerWrapper>
    </StyledBanner>
  );
};
