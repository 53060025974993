import React from "react";
import Reveal from "react-awesome-reveal";

import st from "./st.module.scss";
import { BenefitsCard } from "../../config";
import { keyframes } from "@emotion/react";
import cx from "classnames";
import { useIsMobile } from "@pepdirect/helpers";

const fadeUpSmall = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export function Benefits({
  title,
  items,
}: {
  title: string;
  items: BenefitsCard[];
}): JSX.Element | null {
  const isMobile = useIsMobile();
  return (
    <section className={st.benefitsSection}>
      <h2>{title}</h2>
      <div className={st.benefitsWrapper}>
        {items.map(({ img, title, text }, index) => (
          <Reveal
            keyframes={fadeUpSmall}
            key={title}
            className={cx(st.benefit, ".animated-element")}
            delay={index * 250}
            fraction={isMobile ? 0 : 0.2}
            triggerOnce
          >
            <div>
              <img src={img} width={360} height={360} alt="" />
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </Reveal>
        ))}
      </div>
    </section>
  );
}
