import React, { useContext } from "react";
import Head from "next/head";
import { ModalContext } from "context/modal";
import { LogContext } from "context/log";
import { BannerContext } from "context/banner";
import { Button } from "@pepdirect/ui/button";
import { Banner } from "@pepdirect/ui/banner";
import { META_TITLE, CANONICAL_URL_BASE } from "constants/meta";
import st from "./home.module.scss";

import {
  endpoints,
  healthyHydrationBenefits,
  intelligentConvenienceBenefits,
  sustainableChoiceBenefits,
} from "config";
import { Benefits } from "components/Benefits";
import { useIsMobile } from "@pepdirect/helpers";
import { ConnectedEcoSystemCarousel } from "components/ConnectedEcoSystemCarousel";
import { ExternalLink } from "@pepdirect/ui/ExternalLink";
import cx from "classnames";
import { PAGE_TYPE_ENUM } from "@pepdirect/shared/types";
import { generatePageId } from "@pepdirect/helpers/analyticsLogger";

export default function Home(): JSX.Element {
  const { useLogPageView } = useContext(LogContext);
  const { open } = useContext(ModalContext);
  useLogPageView({
    pageType: PAGE_TYPE_ENUM.home,
    pageCategory: undefined,
    pageTitle: META_TITLE,
    id: generatePageId("root"),
  });

  const {
    bannerDetails,
    setBannerDetails,
    bannerAutoDismiss,
    setBannerAutoDismiss,
  } = useContext(BannerContext);

  const isMobile = useIsMobile();

  return (
    <>
      <Head>
        {/* TODO: og tags */}
        <title>{META_TITLE}</title>
        <link rel="canonical" href={CANONICAL_URL_BASE} />
      </Head>

      {!!bannerDetails && (
        <div className={st.banner}>
          <Banner
            {...bannerDetails}
            setBannerDetails={setBannerDetails}
            bannerAutoDismiss={bannerAutoDismiss}
            setBannerAutoDismiss={setBannerAutoDismiss}
          />
        </div>
      )}

      <section className={st.hero}>
        <div className={st.topHero}>
          <img
            className={cx(st.fruit, st.fruitLeft)}
            src={`${endpoints.cdn}/sodastreampro/fruitLeft1.png`}
            width={1000}
            height={"auto"}
            alt="fruit"
          />
          <img
            className={cx(st.fruit, st.fruitRight)}
            src={`${endpoints.cdn}/sodastreampro/fruitRight1.png`}
            width={1000}
            height={"auto"}
            alt="fruit"
          />
          <div className={st.mobileOnlyText}>This is water. Cooler.</div>

          <div className={st.leftText}>This is water.</div>
          <figure className={st.heroImageWrapper}>
            <img
              className={st.heroImage}
              src={`${endpoints.cdn}/sodastreampro/machineStraightOn1.png`}
              width={420}
              height={583}
              alt=""
            />
          </figure>
          <div className={st.rightText}>Cooler.</div>
        </div>
      </section>
      <section className={st.missionHero}>
        <div>A Personalized and Sustainable Hydration Experience</div>
        <a
          href="/#"
          onClick={(e) => {
            open("SSP_VIDEO_MODAL");
            e.preventDefault();
          }}
        >
          <img
            src={`${endpoints.cdn}/sodastreampro/videoPlayButton.png`}
            alt="Play"
          />
        </a>
      </section>
      <Benefits title={"Healthy Hydration"} items={healthyHydrationBenefits} />
      <section className={st.emptyOfficeHero} />
      <Benefits
        title={"Sustainable Choice"}
        items={sustainableChoiceBenefits}
      />
      <section className={st.connectedEcoSystemHero}>
        <div className={st.topRow}>
          <h3>Connected Eco-System</h3>
          <div className={st.logos}>
            <img
              src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/LogoSSC@2x.png`}
              alt="Logo SSC"
              className={st.ssConnectLogo}
            />
            <div className={st.appStoreLogos}>
              <ExternalLink
                href={
                  "https://apps.apple.com/us/app/sodastream-connect/id1457366052"
                }
              >
                <img
                  src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/AppStore@2x.png`}
                  alt="Apple App Store"
                />
              </ExternalLink>
              <ExternalLink
                href={
                  "https://play.google.com/store/apps/details?id=com.waterstation"
                }
              >
                <img
                  src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/GooglePlay@2x.png`}
                  alt="Google Play"
                />
              </ExternalLink>
            </div>
          </div>
          {isMobile && (
            <div className={st.closerLookButtonWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  open("TAKE_A_CLOSER_LOOK_MODAL");
                }}
              >
                Take a Closer Look
              </Button>
            </div>
          )}
        </div>
        {isMobile && <ConnectedEcoSystemCarousel />}
        <div className={st.arcColumns}>
          <div>
            <div className={st.columnLabel}>
              <span>Smart Equipment</span>
            </div>
            <img
              src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/smartEquipment.png`}
              alt="Smart Equipment"
            />
          </div>
          <div className={st.centerColumn}>
            <div className={st.columnLabel}>
              <span>Personal Account</span>
            </div>
            <img
              src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/phone.png`}
              alt="Personal Account"
            />

            <div className={st.closerLookButtonWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  open("TAKE_A_CLOSER_LOOK_MODAL");
                }}
              >
                Take a Closer Look
              </Button>
            </div>
          </div>
          <div>
            <div className={st.columnLabel}>
              <span>Connected Bottle</span>
            </div>
            <img
              src={`${endpoints.cdn}/sodastreampro/connectedEcoSystem/bottle.png`}
              alt="Connected Bottle"
            />
          </div>
        </div>
      </section>
      <Benefits
        title={"Intelligent Convenience"}
        items={intelligentConvenienceBenefits}
      />
      <section className={st.outdoorHero}>
        <div>
          <h3>Install SodaStream Professional at Your Workplace or Campus</h3>
          <Button type="primary" internalLink="/get-started">
            Get a Quote
          </Button>
        </div>
      </section>
      <section className={st.alreadyEnjoyedBy}>
        <h3>Already Enjoyed By</h3>
        <img
          className={st.alreadyEnjoyedByImage}
          src={`${endpoints.cdn}/sodastreampro/alreadyEnjoyedByLogos.png`}
          alt=""
        />
        <img
          className={st.alreadyEnjoyedByImageMobile}
          src={`${endpoints.cdn}/sodastreampro/alreadyEnjoyedByLogosMobile.png`}
          alt=""
        />
      </section>
    </>
  );
}
