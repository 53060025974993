import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import st from "./st.module.scss";
import SwiperCore, { Autoplay, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { endpoints } from "../../config";

SwiperCore.use([Autoplay]);

const IMAGE_ROOT_PATH = `${endpoints.cdn}/sodastreampro/connectedEcoSystem/mobile`;

export function ConnectedEcoSystemCarousel(): JSX.Element {
  /* We need this magic to "update" swiper after routing happens, otherwise swiper appears scrolled */
  const swiperReference = useRef<SwiperCore | null>(null);
  const router = useRouter();

  useEffect(() => {
    const updateSwiper = () => {
      if (swiperReference.current) {
        swiperReference.current.update();
      }
    };
    router.events.on("routeChangeComplete", updateSwiper);
    return () => {
      router.events.off("routeChangeComplete", updateSwiper);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: SwiperOptions = {
    slidesPerView: "auto" as const,
    freeMode: true,
    speed: 5000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
  };

  return (
    <div className={st.wrapper}>
      <Swiper
        {...options}
        className={st.swiperContainer}
        onSwiper={(current) => (swiperReference.current = current)}
      >
        <SwiperSlide key={1} className={st.slide}>
          <div className={st.columnLabel}>Smart Equipment</div>
          <img
            src={`${IMAGE_ROOT_PATH}/smartEquipment.png`}
            alt="Smart Equipment"
            width={260}
          />
        </SwiperSlide>
        <SwiperSlide key={2} className={st.slide}>
          <div className={st.columnLabel}>Personal Account</div>
          <img
            src={`${IMAGE_ROOT_PATH}/phone.png`}
            alt="Personal Account"
            width={221}
          />
        </SwiperSlide>
        <SwiperSlide key={3} className={st.slide}>
          <div className={st.columnLabel}>Connected Bottle</div>
          <img
            src={`${IMAGE_ROOT_PATH}/bottle.png`}
            alt="Connected Bottle"
            width={243}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
